const skillTree = require("./commonSettings/skillTree");
const countries = require("./commonSettings/countries");
const employmentOptions = require("./commonSettings/employmentOptions");

const config =  {
  "skillTree": skillTree,
  "countries": countries,
  "employmentOptions": employmentOptions,
  formRecruitFieldMapping: {
    "firstName": "First Name",
    "lastName": "Last Name",
    "phone": "Phone",
    "emailAddress": "Email",
    "jobTitle": "Job Title",
    "experienceYears": "Experience in Years",
    "seniorityLevel": "Seniority Level",
    "category": "CATEROGRY",
    "specialities": "SPECIALITY",
    "skills": "SKILLS",
    "workExperience": "Work Experience",
    "salaryExpectations": "Salary Expectations",
    "hasExperienceInGameIndustry": "Has experience in Games industry",
    "platforms": "Platforms",
    "linkedInLink": "LinkedIn profile",
    "gitHubLink": "GitHub, portfolio",
    "location": "Location",
    "employmentOptions": "Employment Options",
    "additionalInfo": "Additional Info",
    "country" : "Country",
    "city" : "City",
    "readyToRelocate": "Relocation",
    "agreeToJoinBoard": "Interested to join Talent Board",
	"agreeToNgConfPass": "Ticket to NG",
    "dateAppliedyyyymmdd": "Date Applied",
    "source": "Source",
	"associatedTags": "Associated Tags",
    "skypeID": "Skype ID",
    "attendOnsite": "Attend onsite"
    // "termsAcceptance": ""
  },
  tagToAssociateWithSubmissions: "Applied GJF23S",
  cvFileFetchTimeout: 5000, // up to 5 sec for fetching the cv file from network
  maxCVFileSizeMB: 5 // in MB, has to be integer
};

// export default config;
module.exports = config;
