import './App.css';
import React, { useRef, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ChippedMultiselect from './ChippedMultiselect';
import GFTypeSelect from './GFTypeSelect';
import GFSelect from './GFSelect';
import PropTypes from 'prop-types';

import useStyles from './styles';
import tbFormConfig from './tbFormConfig';

export const SENDING_NONE = "none";
export const SENDING_IN_PROGRESS = "sending";
export const SENDING_FAILED = "failed";
export const SENDING_SUCCEEDED = "succeeded";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function GFApplicationForm(props) {

    const emailRef = useRef();
    const locationRef = useRef();

    const [emailWarning, setEmailWarning] = useState(false);
    const [locationWarning, setLocationWarning] = useState(false);
    const [resumeWarning, setResumeWarning] = useState(false);

    function specialitiesForCategory(category) {
        const categoryEntry = props.skillTree.find(item => Object.keys(item)[0] === category);
        // const specList = (categoryEntry && categoryEntry[category].map((key,value) => Object.keys(key)[0])) || [];
        const rawSpecList = (categoryEntry && categoryEntry[category].specialities) || [];
        const uniqSpecList = [...new Set(rawSpecList)];
        return uniqSpecList.sort();
    }

    // function skillsForCategory(category) {
    //     const categoryEntry = props.skillTree.find(item => Object.keys(item)[0] === category);
    //     if(!categoryEntry) return [];
    //
    //     const rawSkillList = categoryEntry[category].skills;
    //     const uniqSkillList = [...new Set(rawSkillList)];
    //     return uniqSkillList.sort();
    // }

    const categories = props.skillTree.map((value, key) => Object.keys(value)[0]);

    const countriesList = [...new Set(props.countries.map((value, key) => Object.values(value)[0]))];

    const platformList = props.employmentOptions.platforms;
    const skillList = props.employmentOptions.skills;

    function platforms(){
        return [...new Set(platformList)];
    }
    const seniorityLevels = props.employmentOptions.seniorityLevels;

    const classes = useStyles();

    // const workExperiencePossible = [
    //   "0-1 year",
    //   "1-3 years",
    //   "4-5 years",
    //   "5+ years"
    // ];

    const employmentOptionsPossible = props.employmentOptions.employmentOptions;

    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        emailAddress: "",
        // default non empty value makes sure field title pops out of a button to make it look nicer
        category: "",
        specialities: [],
        skills: [],
        experienceYears: null,
        // workExperience: workExperiencePossible[1],
        // default non empty value makes sure field title pops out of a button to make it look nicer
        seniorityLevel: "",
        hasExperienceInGameIndustry: false,
        platforms: [],
        salaryExpectations: "0",
        cvFile: null,
        linkedInLink: "",
        gitHubLink: "",
        jobTitle: "",
        location: "",
        // Exactly event site has zero employment options, because it's not about permanent employment at all
        employmentOptions: [],
        additionalInfo: "",
        country: "",
        city: "",
        readyToRelocate: false,
        agreeToJoinBoard: false,
        agreeToNgConfPass: false,
        attendOnsite: false,
        termsAcceptance: false,
        associatedTags: 'Applied GJF23S'
    });

    const updateField = e => {
        // console.log('updateField', e.target.name, e.target.value, typeof e.target.value)
        let value = e.target.value;
        if(e.target.type === "checkbox") {
            value = e.target.checked && e.target.value;
        } else if (e.target.files && (e.target.files.length > 0)) {
            if((e.target.files[0].size  / 1024 / 1024) < tbFormConfig.maxCVFileSizeMB){
                value = e.target.files[0];
                setResumeWarning(false);
            } else {

                setResumeWarning(true);
            }
        }

        doUpdateField(e.target.name, value);
    };

    const updateCategoryField = e => {
        // cleaning out the specialities field when category is changed
        doUpdateFields([{"category": e.target.value}, {"specialities": []}]);
    }

    // Cannot be called several times in the update cycle as the next one will override the previous one
    const doUpdateFields = nameValuePairs => {
        let updateObject = {...formValues};
        for (let entry of nameValuePairs) {
            const name = Object.keys(entry)[0];
            const value = entry[name];
            updateObject[name] = value;
        }
        console.log('updateObject:', updateObject);
        setFormValues(updateObject);
    };

    // Cannot be called several times in the update cycle as the next one will override the previous one
    const doUpdateField = (fieldName, fieldValue) => {
        setFormValues({
            ...formValues,
            [fieldName]: fieldValue
        });
    }

    function handleSubmit(e) {
        e.preventDefault();

        if(formValues.attendOnsite === false) {
            formValues.attendOnsite = -1;
        } else {
            formValues.attendOnsite = 1;
        }

        formValues.associatedTags = 'Applied GJF23S';
        let locEnabled = locationRef.current.querySelector("input").value !== '' && countriesList.includes(locationRef.current.querySelector("input").value);
        let emailEnabled = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailRef.current.querySelector("input").value);
        if(locEnabled){
            setLocationWarning(false);
        }
        if(emailEnabled){
            setEmailWarning(false);
        }

        if(locEnabled && emailEnabled){
            // converts indices of employmentOptions to array of strings to post
            if(props.onSubmitWish) {
                props.onSubmitWish({
                    ...formValues
                    , employmentOptions: formValues.employmentOptions.map(index => employmentOptionsPossible[index])
                });
            }
        } else {
            if(!emailEnabled){
                setEmailWarning(true);
                window.scrollTo(0, emailRef.current.offsetTop) ;
            } else {
                if(!locEnabled){
                    setLocationWarning(true);
                    window.scrollTo(0, locationRef.current.offsetTop) ;
                }
            }
        }
    }

    function specialitiesChanged(e, value, reason) {
        if(Array.isArray(value) && value.length > 3) {
            value = value.slice(0, 3);
        }
        doUpdateFields([{"specialities": value}]);
    }

    function skillsChanged(e, value, reason) {
        if(Array.isArray(value) && value.length > 5) {
            value = value.slice(0, 5);
        }
        doUpdateFields([{"skills": value}]);
    }

    function platformsChanged(e, value, reason){
        if(value.includes('Not applicable')){
            doUpdateField('platforms', ['Not applicable']);
        } else {
            doUpdateField('platforms', value);
        }
    }


    function countryChanged(e, value, reason) {
        doUpdateField('country', value);
    }

    function fileSelectionButtonLabel() {
        return formValues.cvFile ? formValues.cvFile.name : `Resume`;
    }

    // function employmentOptionChanged(e) {
    //   const value = parseInt(e.target.value);
    //   const checked = e.target.checked;
    //   let selectionList = formValues.employmentOptions
    //   if(checked) {
    //     selectionList.push(value)
    //   } else {
    //     const existingIndex = selectionList.indexOf(value)
    //     if( existingIndex === -1) {
    //       console.error("Trying to remove value " + value + " that is unexpectedly not present")
    //     } else {
    //       selectionList.splice(existingIndex, 1)
    //     }
    //   }
    //   doUpdateField("employmentOptions", selectionList);
    // }

    const fileControlLabelClassName = formValues.cvFile ?
    classes.fileInputControlLabelFileSelected :
    classes.fileInputControlLabelFileNonSelected;


    const requiredPortfolio = formValues.category === "Art & Animation";

    return (

        <form name="personregistration" onSubmit={handleSubmit}>
            <Grid className={classes.registrationForm}
                container
                spacing={4}
                direction="column"
                alignItems="stretch"
            >
                <Grid item className={classes.introText}>
                    <h2>MENA Games Job Fair application form</h2>
                    Join our event to connect with hiring studios from MENA region, attend expert industry talks, and gain insights on boosting your career. Apply through this form, and once your application has been screened, you will receive an invitation to the event platform. For further details about the event, please visit <a target="_blank" rel="noopener noreferrer" href="https://menagamesjobfair.com/">www.menagamesjobfair.com</a>
                </Grid>

                <Grid container item direction="row" xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl required className={classes.formLine}>
                            <TextField
                                required
                                className={classes.textFieldInput}
                                variant="filled"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={updateField}
                                placeholder={'First Name*'}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl required className={classes.formLine}>
                            <TextField
                                required
                                className={classes.textFieldInput}
                                variant="filled"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={updateField}
                                placeholder={'Last Name*'}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item direction="row" xs={12} spacing={2}>
                    <Grid item xs={6} ref={emailRef}>
                        <FormControl required className={classes.formLine}>
                            <TextField
                                required
                                type="email"
                                className={classes.textFieldInput}
                                variant="filled"
                                name="emailAddress"
                                value={formValues.emailAddress}
                                onChange={updateField}
                                placeholder={'Email address*'}
                            />
                        </FormControl>
                        {emailWarning && (
                            <Alert severity="error">Please fill correct email!</Alert>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl  className={classes.formLine}>
                            <TextField
                                className={classes.textFieldInput}
                                variant="filled"
                                name="phone"
                                type="tel"
                                value={formValues.phone}
                                onChange={updateField}
                                placeholder={'Phone'}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {/*
                <Grid item xs={12}>
                    <FormControl className={classes.additionalInformationFormControl}>
                        <InputLabel
                            className={classes.employmentOptionsLabel}
                            shrink
                        ></InputLabel>
                        <FormControlLabel
                            className={classes.inputLabel + ' white-space'}
                            shrink
                            control={<Checkbox checked={formValues.attendOnsite !== -2 ? formValues.attendOnsite : false } onChange={updateField} name="attendOnsite" value="0"/>}
                            label="I'd like to attend the onsite event on 28 April at Innopoli 2, Espoo, Finland (Ticket Cost €15)"
                        />
                        {/*<div className={classes.termsText}>The Onsite event on October 28th is full and we can no longer accommodate any more participants</div>*/}
                    {/*</FormControl>
                    <FormHelperText>
                        <p>You will receive access to:</p>
                        <ul class="features">
                            <li class="live">Live Sessions</li>
                            <li class="meeting">1:1 Meetings</li>
                            <li class="lunch">Lunch at the Venue</li>
                            <li class="workshops">Workshops</li>
                            <li class="network">Networking with Studios</li>
                            <li class="afterparty">Afterparty at HypeHype HQ (Kamppi)</li>
                        </ul>
                    </FormHelperText>
                </Grid>*/}

                <Grid container item direction="row" xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl required className={classes.formLine}>
                            <TextField
                                required
                                className={classes.textFieldInput}
                                variant="filled"
                                name="jobTitle"
                                value={formValues.jobTitle}
                                onChange={updateField}
                                placeholder={'Job Title*'}
                            />
                        </FormControl>
                        <FormHelperText>Please specify your current job title</FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className={classes.nonLineFormElement}>
                            <InputLabel
                                id="cvFileLabel"
                                className={classes.inputLabel}
                                classes={{asterisk: classes.inputLabelAsterisk}}
                                shrink
                            >Resume (Attachment)</InputLabel>
                            <Button
                                className={classes.fileInputControl}
                                variant="contained"
                                component="label"
                                startIcon={<AttachFileIcon style={{ fontSize: 14 }} /> }
                            >
                                <span className={fileControlLabelClassName}>{fileSelectionButtonLabel()}</span>
                                <input
                                    type="file"
                                    name="cvFile"
                                    id="cvFileInput"
                                    onChange={updateField}
                                    hidden
                                />
                            </Button>
                            {resumeWarning && (
                                <Alert severity="error">Please select a file smaller than 5GB!</Alert>
                            )}
                        </FormControl>
                        <FormHelperText><span>Please select a file smaller than 5GB</span></FormHelperText>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormControl required className={classes.formLine}>
                        <TextField
                            required
                            type="url"
                            className={classes.textFieldInput}
                            variant="filled"
                            name="linkedInLink"
                            value={formValues.linkedInLink}
                            onChange={updateField}
                            placeholder={'LinkedIn profile*'}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl required className={classes.additionalInformationFormControl}>
                        <InputLabel
                            required
                            className={classes.employmentOptionsLabel}
                            classes={{asterisk: classes.inputLabelAsterisk}}
                            shrink
                        >Do you have experience in the Games industry?
                        </InputLabel>

                        <RadioGroup required row name="hasExperienceInGameIndustry" value={formValues.hasExperienceInGameIndustry} onChange={updateField}>
                            <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid container item direction="row" xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <GFSelect
                            labelText="Category"
                            name="category"
                            options={categories}
                            value={formValues.category}
                            onChange={updateCategoryField}
                            required={true}
                        />
                        <FormHelperText>Please select one category that you would like to continue working in</FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <GFSelect
                            labelText="Seniority Level"
                            name="seniorityLevel"
                            options={seniorityLevels}
                            value={formValues.seniorityLevel}
                            onChange={updateField}
                            required={true}
                        />
                        <FormHelperText><span>Please select from the dropdown list<br/><i>Your current seniority level, how you would consider your seniority</i></span></FormHelperText>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formLine}>
                        <TextField
                            type="url"
                            required={requiredPortfolio}
                            className={classes.textFieldInput}
                            variant="filled"
                            name="gitHubLink"
                            value={formValues.gitHubLink}
                            onChange={updateField}
                            placeholder={'Link to your portfolio (GitHub, ArtStation etc.) or web-site'}
                        />
                    </FormControl>
                    {requiredPortfolio && (<FormHelperText><span>Please enter your portfolio link that includes your artwork and for animators the link of your animations</span></FormHelperText>)}
                </Grid>


                <Grid item xs={12}>
                    <ChippedMultiselect
                        name="specialities"
                        labelText="Speciality"
                        options={specialitiesForCategory(formValues.category)}
                        onChange={specialitiesChanged}
                        value={formValues.specialities}
                        required={true}
                    />
                    <FormHelperText>Please specify max. 3 specialties within your category. If a specialty is not available in the list, enter it in the text field</FormHelperText>
                </Grid>

                <Grid item xs={12}>
                    <ChippedMultiselect
                        name="skills"
                        labelText="Key Skills – Tech Stack, Technologies and Tools"
                        options={skillList}
                        onChange={skillsChanged}
                        value={formValues.skills}
                        required={true}
                    />
                    <FormHelperText>Please list your tech skills max. 5 and stack e.g. C++, Unity, Houdini etc. If a skill is not available in the list, enter it in the text field</FormHelperText>
                </Grid>

                <Grid container item direction="row" xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <ChippedMultiselect
                            classes="platforms-select-option"
                            name="platforms"
                            labelText="Platforms you have worked with"
                            options={platforms()}
                            onChange={platformsChanged}
                            value={formValues.platforms}
                            required={true}
                        />
                    </Grid>

                    <Grid item xs={6} ref={locationRef}>
                        <GFTypeSelect
                            name="no"
                            labelText="Location - Country"
                            options={countriesList}
                            onChange={countryChanged}
                            value={formValues.country}
                            required={true}
                        />
                        {locationWarning && (
                            <Alert severity="error">Please fill correct country!</Alert>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormControl required className={classes.additionalInformationFormControl}>
                        <InputLabel
                            required
                            className={classes.employmentOptionsLabel}
                            classes={{asterisk: classes.inputLabelAsterisk}}
                            shrink
                        >Are you willing to relocate?<br/>
                        <i>If the right job opportunity comes - will you consider relocation?</i>
                        </InputLabel>
                        <RadioGroup required row name="readyToRelocate" value={formValues.readyToRelocate} onChange={updateField}>
                            <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <Divider/>
                </Grid>

                <Grid item xs={12} hidden={true}>
                    <h3 className={classes.inFormBlockHeader}>GameDev Talent Board</h3>
                    <FormControl className={classes.additionalInformationFormControl}>
                        <InputLabel
                            className={classes.employmentOptionsLabel}
                            shrink
                        >Publish your anonymous profile on our new GameDev Talent Board by showing only your expertise and enable chances of job opportunities </InputLabel>
                        <FormControlLabel
                            className={classes.inputLabel}
                            shrink
                            control={<Checkbox checked={formValues.agreeToJoinBoard} onChange={updateField} name="agreeToJoinBoard"/>}
                            label="I'm interested, send me additional info"
                        />
                    </FormControl>
                    <Divider/>
                </Grid>

                {/*<Grid item xs={12}>
                    <FormControl className={classes.additionalInformationFormControl}>
                        <InputLabel
                            className={classes.employmentOptionsLabel}
                            shrink
                        ></InputLabel>
                        <FormControlLabel
                            className={classes.inputLabel + ' white-space'}
                            shrink
                            control={<Checkbox checked={formValues.agreeToNgConfPass} onChange={updateField} name="agreeToNgConfPass" value="Yes"/>}
                            label="I'd like a Free online pass for Nordic Game '23 Spring, 23-26 May"
                        />
                    </FormControl>
                    <Divider/>
                        </Grid>*/}



                <Grid item xs={12}>
                    <h3 className={classes.inFormBlockHeader}>Terms and Conditions</h3>
                    <FormControl className={classes.additionalInformationFormControl}>
                        <div className={classes.termsText}>We collect your data to make the event as successful as possible, and connect you with matching job opportunities. We use the Zoho Recruit service to do so, your data will be shared with Nine66, the main organizers of MENA Games Job Fair. The data we collect will help us create your profile in the PINE online platform, which we use during the event, but you will have a possibility to modify it during the fair to better represent you. You will be able to remove your data after the event, if you so wish.</div>
                        <div className={classes.termsText}>As of 1st December 2023, we cannot guarantee we will be able to screen your application in time due to the large number of applications we are receiving.</div>

                        <div className={classes.termsText}>Please familiarise yourself with our <a href="https://www.menagamesjobfair.com/privacy-policy-gft">Privacy Policy</a> and <a href="https://www.menagamesjobfair.com/terms-of-service-gft">Terms of Service</a></div>
                        <FormControlLabel
                            required
                            className={classes.inputLabel}
                            shrink
                            control={<Checkbox required onInvalid={(e) => {e.target.setCustomValidity('I have read and agree to the Privacy Policy and Terms of Service')}} checked={formValues.termsAcceptance} onChange={(e) => {e.target.setCustomValidity('');updateField(e);}} name="termsAcceptance" value={true}/>}
                            label="I accept Privacy Policy and Terms of Service"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <h3 className={classes.inFormBlockHeader}>Disclaimer</h3>
                    <FormControl className={classes.additionalInformationFormControl}>
                        <div className={classes.termsText}>As a global platform we expect all of our community members to show mutual respect and understanding towards one another. We want MENA Games Job Fair to be a safe space for everyone, and to provide equal opportunity to its participating talents. Any form of discrimination or harassment will not be tolerated, and as such lead to blacklisting.</div>
                        <FormControlLabel
                            required
                            className={classes.inputLabel}
                            shrink
                            control={<Checkbox required onInvalid={(e) => {e.target.setCustomValidity('I have read and accept the disclaimer')}} name="disclaimerAcceptance"  onChange={(e) => {e.target.setCustomValidity('');}} value={true}/>}
                            label="Accept"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    { props.sendingStatus === SENDING_FAILED &&
                        <Alert className={classes.errorAlert} severity = "error">
                            We are sorry, something went wrong. Please, try again later or contact us by email.
                            {props.errorMessage && " Server tells: "} {props.errorMessage}
                        </Alert>
                    }
                    { (!props.sendingStatus || props.sendingStatus === SENDING_NONE || props.sendingStatus === SENDING_FAILED ) &&
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<ArrowRightAltIcon/>}
                            color="secondary">
                            Register Now
                        </Button>
                    }
                    { props.sendingStatus === SENDING_IN_PROGRESS &&
                        <CircularProgress/>
                    }
                    {
                        // For messages before posting such as e.g. file size limit
                        props.sendingStatus === SENDING_NONE && props.errorMessage &&
                        <Alert className={classes.errorAlert} severity = "error">{props.errorMessage}</Alert>
                    }
                    <Backdrop className={classes.backdrop} open={props.sendingStatus === SENDING_SUCCEEDED}>
                        <Alert severity="success">
                            Thank you for your application. Please check your inbox (other folders) for next steps in joining the event.
                        </Alert>
                    </Backdrop>
                </Grid>
            </Grid>
        </form>
        );
    }

    GFApplicationForm.propTypes = {
        maxCVFileSizeMB: PropTypes.number,
        sendingStatus: PropTypes.oneOf([SENDING_NONE, SENDING_IN_PROGRESS, SENDING_FAILED, SENDING_SUCCEEDED]),
        errorMessage: PropTypes.string,
        onSubmitWish: PropTypes.func,
    };

    export default GFApplicationForm;
